@import '@/scss/base/variables';
@import '../../base/variables';
@import '../../lib/components/slider';
@import './listing/listing-items';

/* content ------------------------------------- */

#content {
  padding-top: 0;

  @media only screen and (min-width: #{$breakpoint-s + 1}) {
    display: grid;
    gap: 36px 32px;
    grid-template-areas: 'keyvisual keyvisual' 'side main';
    grid-template-columns: 220px 1fr;
  }

  .for-pc {
    @media only screen and (max-width: $breakpoint-s) {
      display: none;
    }
  }

  .for-sp {
    @media only screen and (min-width: #{$breakpoint-s + 1}) {
      display: none;
    }
  }

  h2 {
    color: #000;
    font-size: 13px;
    font-weight: normal;
    margin-bottom: 10px;
  }
}

#keyvisual {
  grid-area: keyvisual;
}

#main {
  grid-area: main;

  .section {
    margin-bottom: 35px;
  }
}

#side {
  grid-area: side;

  .section {
    margin-bottom: 24px;

    h2 {
      @media only screen and (max-width: $breakpoint-s) {
        font-size: 16px;
        font-weight: bold;

        br {
          display: none;
        }
      }
    }

    img {
      @media only screen and (max-width: $breakpoint-s) {
        height: auto;
        width: 100%;
      }
    }

    &:first-child {
      margin-top: 0;

      span.new,
      span.sale {
        color: $red;
      }
    }

    &.banner-vintage img {
      border: none;
    }

    &.voice {
      @media only screen and (max-width: $breakpoint-s) {
        display: none;
      }
    }

    &.banner-matsumoto {
      @media only screen and (max-width: $breakpoint-s) {
        display: none;
      }
    }
  }

  .text {
    line-height: 150%;
  }
}

/* slide ------------------------------------------------------------------- */

/* stylelint-disable-next-line selector-type-no-unknown */
slider {
  > div {
    min-width: calc(100% * 2 / 3);

    @media only screen and (max-width: $breakpoint-s) {
      min-width: 100%;
    }

    &:first-child {
      margin-left: calc(100% * 1 / 6);

      @media only screen and (max-width: $breakpoint-s) {
        margin-left: 0;
      }
    }
  }
}

#main-slide {
  margin: 0 -10px 18px;
  position: relative;

  @media only screen and (min-width: #{$breakpoint-s + 1}) {
    margin: 0 0 6px;
  }

  .slides {
    padding-bottom: 30px;

    .slide-item {
      @media only screen and (min-width: #{$breakpoint-s + 1}) {
        box-sizing: border-box;
        padding-right: 2px;
        width: 653.333px;
      }

      img {
        height: 100%;
        object-fit: cover;
        vertical-align: bottom;
        width: 100%;
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      @media only screen and (max-width: $breakpoint-s) {
        display: none;
      }
    }
  }

  .swiper-container {
    @mixin transform($slidesPerView) {
      transform: translate3d(calc(100% * (($slidesPerView - 1) / 2) / $slidesPerView), 0, 0);
    }

    .swiper-wrapper {
      @include transform(1.5);

      @media only screen and (max-width: $breakpoint-s) {
        @include transform(1);
      }
    }
  }
}

/* heading ------------------------------------------------ */

#content .heading {
  display: flex;

  h2 {
    float: none;
    font-size: 16px;
    font-weight: bold;
  }
}

/* listing ------------------------------------------------ */

.listing {
  .photo {
    a {
      @media only screen and (min-width: #{$breakpoint-s + 1}) {
        aspect-ratio: 170 / 127;
      }
    }
  }
}

/* top-banner ------------------------------------------------ */

.top-banner {
  margin-bottom: 30px;

  img {
    height: auto;
    vertical-align: bottom;
    width: 100%;
  }
}

/* pickup ------------------------------------------------ */

.pickup {
  .listing li {
    .name-price {
      @media only screen and (min-width: #{$breakpoint-s + 1}) {
        display: flex;
        justify-content: space-between;

        .price {
          flex-shrink: 0;
        }
      }
    }
  }
}

/* following ------------------------------------------------ */

.following {
  margin-top: 0;

  .listing {
    display: flex;
    gap: 16px;
    overflow-x: auto;
    padding-bottom: 12px;
    width: 728px;

    @media only screen and (max-width: $breakpoint-s) {
      margin: 0 -10px;
      padding-left: 10px;
      width: auto;
    }

    li {
      min-width: 30%;
      overflow: visible;
      position: relative;

      @media only screen and (max-width: $breakpoint-s) {
        min-width: 40%;
      }

      &:last-child::after {
        content: '';
        height: 1px;
        left: 100%;
        position: absolute;
        top: 0;
        width: 16px;
      }
    }
  }
}

/* items ------------------------------------------------ */

.items {
  @media only screen and (max-width: $breakpoint-s) {
    display: none;
  }
}

/* galleryshop ------------------------------------------------ */

.galleryshop {
  ul {
    display: flex;
    gap: 16px;
    overflow-x: auto;
    padding-bottom: 12px;
    width: 728px;

    @media only screen and (max-width: $breakpoint-s) {
      margin: 0 -10px;
      padding-left: 10px;
      width: auto;
    }

    li {
      min-width: 45%;
      position: relative;
      width: 45%;

      @media only screen and (max-width: $breakpoint-s) {
        min-width: 80%;
        width: 80%;
      }

      &:last-child::after {
        content: '';
        height: 1px;
        left: 100%;
        position: absolute;
        top: 0;
        width: 16px;
      }

      a {
        color: inherit;
        display: block;

        .photo {
          margin-bottom: 12px;

          img {
            aspect-ratio: 169 / 90;
            height: auto;
            object-fit: cover;
            vertical-align: bottom;
            width: 100%;
          }
        }

        .info {
          display: flex;
          gap: 12px;

          .logo {
            text-align: center;
            width: 60px;

            img {
              border-radius: 50%;
              object-fit: cover;
            }
          }

          .text {
            flex: 1;

            .title {
              font-weight: bold;
              margin-bottom: 4px;
            }

            .lead {
              -webkit-box-orient: vertical;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}

/* Newsletter ------------------------------------------------ */

.newsletter {
  ul {
    display: flex;
    gap: 16px;
    overflow-x: auto;
    padding-bottom: 12px;

    @media only screen and (max-width: $breakpoint-s) {
      margin: 0 -10px;
      padding-left: 10px;
    }

    li {
      min-width: 45%;
      position: relative;
      width: 45%;

      @media only screen and (max-width: $breakpoint-s) {
        min-width: 80%;
        width: 80%;
      }

      &:last-child::after {
        content: '';
        height: 1px;
        left: 100%;
        position: absolute;
        top: 0;
        width: 16px;
      }

      > a {
        color: inherit;
        display: block;

        &:hover {
          text-decoration: none;
        }

        .photo {
          margin-bottom: 12px;

          img {
            aspect-ratio: 4 / 3;
            height: auto;
            object-fit: cover;
            vertical-align: bottom;
            width: 100%;
          }
        }

        .info {
          h3 {
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 4px;
          }
        }

        &:hover h3 {
          text-decoration: underline;
        }
      }
    }
  }
}

/* new-items -------------------------------------------------- */

.new-items {
  .listing {
    gap: 10px 15px;

    @media only screen and (max-width: $breakpoint-s) {
      gap: 4px;
    }

    li:nth-child(n + 9) {
      @media only screen and (max-width: $breakpoint-s) {
        display: none;
      }
    }

    .photo {
      @media only screen and (max-width: $breakpoint-s) {
        margin-bottom: 0;
      }

      a {
        aspect-ratio: 1 / 1;
      }
    }

    .info {
      padding: 0;

      .price {
        text-align: right;
      }

      @media only screen and (max-width: $breakpoint-s) {
        display: none;
      }
    }
  }
}

/* news ---------------------------------------------------- */

.news {
  table {
    border-collapse: collapse;
  }

  td {
    font-size: 12px;
    line-height: 1.5;
    vertical-align: top;

    a {
      display: inline-block;
      margin-bottom: 6px;
    }
  }

  .date {
    font-size: 12px;
    padding-right: 12px;
    white-space: nowrap;
  }
}

/* banner ------------------------------------------------------ */

.banners {
  li {
    margin-bottom: 20px;
  }

  a {
    display: block;
  }
}

/* voice ------------------------------------------------------ */

.voice {
  ul {
    border: #e8e3e2 1px solid;
    padding: 12px 12px 0;
  }

  li {
    border-top: #e8e3e2 1px dotted;
    padding: 12px 0;

    &:first-child {
      border-top: none;
      padding-top: 0;
    }
  }

  h3 {
    font-weight: normal;
    margin-bottom: 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 127px;
  }

  .info {
    display: flex;
    gap: 10px;

    .photo img {
      border: 1px solid #f0ece7;
      vertical-align: bottom;
    }

    .rating a {
      color: #51452e;
    }
  }
}

.rating-text {
  margin-top: 10px;
}
